/* QuestionAuthor.css */

.question-author-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  height: 100vh;
  box-sizing: border-box;
  max-height: 67vh;

  .sidebar {
    width: 25%;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .sidebar h3 {
    margin-top: unset;
    margin-bottom: unset;
    font-size: 18px;
    color: #333;
  }

  .add-question-buttons-container {
    display: flex;
    gap: 2px;
  }

  .add-dropdown {
    position: relative;
  }

  .add-button {
    padding: 6px 15px;
    background-color: #f82e2e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
  }

  .add-button:hover {
    background-color: #b30000;
  }

  .question-upload-button {
    padding: 6px 15px;
    background-color: #f82e2e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    width: max-content;
  }

  .question-upload-button:hover {
    background-color: #b30000;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -128%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 100%;
    padding: 10px 0;
  }

  .add-dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-item {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .question-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 87%;
    overflow-y: auto;
  }

  .question-item {
    padding: 10px;
    margin-right: 4px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .question-delete-icon {
      display: none;
      position: absolute;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: #fdd5d5;
      border-radius: 0 5px 5px 0;
    }

    .question-delete-icon:hover {
      color: red;
    }

    &:hover .question-delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .question-item:hover {
    background-color: #fdd5d5;
  }

  .question-item.selected {
    border: 2px solid #ff9aa2;
  }

  .question-item h4 {
    font-size: 14px;
    margin: 0;
    line-height: unset;
  }

  .question-type {
    font-size: 12px;
    color: #999;
  }

  .question-status {
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
  }

  .main-content {
    width: 75%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    max-height: 67vh;
    overflow-y: auto;
  }

  .question-header select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .question-body label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .question-body .ck-editor__editable {
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
  }

  .question-body .ck-editor {
    ul {
      li {
        list-style: square !important;
      }
    }

    ol {
      li {
        list-style: decimal !important;
      }
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .media-buttons button {
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff0000;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  .upload-image {
    label {
      margin-top: 10px;
      margin-right: 10px;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #ff0000;
      color: white;
      cursor: pointer;
      font-size: 14px;
    }

    label:hover {
      background-color: #b30000;
    }
  }

  .question-media {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    .question-media-item-image {
      position: relative;
      display: inline-block;
      overflow: hidden;

      .question-media-image {
        display: block;
        max-width: 100px;
        max-height: 100px;
        width: auto;
        height: auto;
        object-fit: contain;
        object-position: center;
        border: 1px solid #b50505;
        cursor: pointer;
      }

      .question-media-video {
        display: block;
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
        object-fit: contain;
        object-position: center;
        border: 1px solid #b50505;
      }

      .question-media-audio {
        display: block;
        height: 100px;
        border: 1px solid #b50505;
      }

      .remove-media {
        position: absolute;
        top: 5px;
        right: 5px;
        background: red;
        color: white;
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .mcq-options {
    margin-top: 20px;
  }

  .mcq-type {
    margin-bottom: 20px;
  }

  .mcq-type label {
    font-weight: bold;
  }

  .mcq-type select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .choice-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
  }

  .choice-item.no-bottom-margin {
    margin-bottom: 0 !important;
  }

  .choice-item input[type="checkbox"] {
    margin-top: unset;
    position: unset;
  }

  .choice-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 14px;
  }

  .separator-text {
    color: #999;
    font-size: 14px;
  }

  .upload-photo-button {
    padding: 10px;
    background-color: #f5f5f5;
    color: #555;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5px;
  }

  .upload-photo-button:hover {
    background-color: #ffe6e6;
    border-color: #ff9191;
    color: #ff1818;
  }

  .delete-choice-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 6px 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .delete-choice-button:hover {
    background-color: #d9363e;
  }

  .add-choice-button {
    margin-top: 15px;
    padding: 5px 10px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .add-choice-button:hover {
    background-color: #b30000;
  }

  .error-text {
    color: #ff0000 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .subjective-options {
    margin-top: 20px;

    .keywords-group {
      border: 1px solid #b9b9b9;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .keyword-variants {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .keyword-input {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
    }

    .keyword-variant-input {
      position: relative;
      width: 150px;
    }

    .keyword-marks-input {
      width: 100px;
      max-height: max-content;
      border: 2px solid #9e0202 !important;
    }

    .remove-variant-button {
      display: none;
    }

    .keyword-variant-input:hover .remove-variant-button {
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 18px;
      cursor: pointer;
      color: #ff0000;
    }

    .add-variant-button {
      padding: 22px 14px;
      color: #ff0000;
      background-color: transparent;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 14px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
    }

    .add-variant-button:hover {
      background-color: #fbe6e6;
    }

    .add-keyword-button {
      padding: 5px 10px;
      color: #ff0000;
      background-color: transparent;
      border: 1px dashed #ff0000;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      width: 100%;
      transition: 0.5s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .add-keyword-button:hover {
      background-color: #ff0000;
      border: 1px solid #ff0000;
      color: white;
    }

    .marking-scheme-container {
      display: flex;
      gap: 20px;
      align-items: center;

      label {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .mark-container {
    margin-top: 20px;

    .mark-inputs {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      justify-content: space-between;

      label {
        opacity: 0.7;
        font-weight: 500;
        font-size: 12px;
      }

      input:disabled {
        cursor: not-allowed;
        background-color: #f1f1f1 !important;
        color: #000 !important;
      }
    }
  }

  .mark-container h4 {
    margin-bottom: 10px;
  }

  .mark-container input {
    width: 200px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .question-save-btn {
    height: max-content;
    padding: 10px 20px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 3px;
    transition: 0.5s ease;
  }

  .question-save-btn:hover:not(:disabled) {
    background-color: #b30000;
  }

  .question-save-btn:disabled {
    background-color: #ccc;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.swal2-container {
  z-index: 10100 !important;
}

.ck.ck-balloon-panel,
.ck.ck-dropdown__panel,
.ck.ck-tooltip {
  z-index: 10050 !important; /* Adjust this value to be higher than your modal's z-index */
}

.ck.ck-editor__editable_inline {
  z-index: auto; /* Keep the editor content at the default level */
}
