.quiz-container {
  min-height: 100vh;
  background-image: url("./QUIZ_BG.png");
  background-position: center;
  background-size: contain;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d1d0d0;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .quiz-card {
    min-width: 90dvw;
    max-width: 90%;
    max-height: 89dvh;
    min-height: 89dvh;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;

    .quiz-home-loader-container {
      height: 89dvh;
      width: 90dvw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .quiz-sections {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 89dvh;
  }

  .left-section {
    padding: 2rem 1rem;
    border-right: 1px solid #d4d3d3;
  }

  .company-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .company-logo {
    width: 140px;
    height: 140px;
    padding: 10px;
    object-fit: contain;
    margin-right: 15px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #bababa;
  }

  .company-info h1 {
    font-size: 1.25rem;
    color: #333;
    margin: 0;
  }

  .company-info p {
    color: #666;
    margin: 0.25rem 0 0 0;
    font-size: 0.9rem;
  }

  .quiz-info h2 {
    color: #333;
    margin: 0 0 1.5rem 0;
  }

  .quiz-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .detail-row {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;
  }

  .label {
    color: #666;
    font-weight: 700;
  }

  .value {
    color: #333;
    font-weight: 400;
  }

  .right-section {
    padding: 1rem 0rem;
  }

  .right-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  .right-section h2 {
    color: #333;
    font-size: 1.1rem;
    max-width: 80%;
    margin-bottom: unset;
  }

  .right-section hr {
    border-top: #d4d3d3 solid 1px;
  }

  .gp-logo-home {
    width: 10%;
    margin-bottom: 5px;
  }

  .instructions-list {
    list-style: none;
    padding: 0 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 55vh;
    overflow-y: auto;
  }

  .instructions-list li {
    position: relative;
    padding-left: 1.5rem;
    color: #555;
    line-height: 1.5;
  }

  .instructions-list li::before {
    content: "•";
    color: #ee3835;
    position: absolute;
    left: 0;
    font-weight: bold;
  }

  .quiz-instructions-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1.5rem 0;
    padding: 0 1rem;
  }

  .quiz-instructions-checkbox input[type="checkbox"] {
    position: unset;
    width: 1rem;
    height: 1rem;
    margin: 0;
  }

  .start-quiz-btn {
    position: absolute;
    bottom: 2rem;
    right: 1.5rem;
    background-color: #ee3835;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .start-quiz-btn:hover {
    background-color: #9e1917;
  }

  .start-quiz-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .quiz-survey-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    max-height: 75vh;
    overflow-y: auto;
  }

  .radio-field {
    input[type="radio"] {
      height: 1rem;
      width: 1rem;
    }
  }

  .custom-field-checkbox-container {
    input[type="checkbox"] {
      height: 1rem;
      width: 1rem;
    }
  }

  .quiz-survey-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;

    button {
      width: 100px;
    }

    .btn-outline-danger:hover {
      background-color: #f7e3e3;
      color: #ee3835;
    }
  }

  @media (max-width: 1024px) {
    .quiz-container {
      padding: 0.5rem;
      background-color: #f8f8f8;
      background-image: none;
    }

    .quiz-card {
      max-width: 98%;
      max-height: unset;
      min-height: unset;
    }

    .quiz-sections {
      grid-template-columns: 1fr;
      height: unset;
    }

    .left-section {
      border-right: none;
      border-bottom: 1px solid #eee;
      max-width: unset;
    }

    .right-section-header h2 {
      font-size: 14px;
      max-width: 75%;
    }

    .gp-logo {
      width: 20%;
    }

    .instructions-list {
      max-height: unset;
    }

    .start-quiz-btn {
      position: relative;
      bottom: auto;
      right: auto;
      margin: 2rem;
      width: calc(100% - 4rem);
    }
  }
}
