.form-container {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
}

.quiz-settings-page {
  max-height: 59.5vh;
  overflow-y: auto;
  padding-right: 15px;

  .proctoring-group {
    border: 1px solid;
    border-radius: 15px;
    padding: 20px;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-group.hide {
    display: none;
  }

  .form-group.violation-check-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: unset;

    input[type="checkbox"] {
      margin: 0px;
      left: unset;
      height: 18px;
      width: 18px;
    }

    label {
      margin: 0px;
    }
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 0px;
  }

  .form-group.toggle-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-title {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #51636f;

    &.disabled {
      opacity: 0.3;
    }
  }

  .form-input,
  .form-textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    color: black;
  }

  .error-border {
    border: 1px solid #f93f3f !important;
  }

  .ck-error-border {
    .cke_inner {
      border: 1px solid #f93f3f !important;
    }
  }

  .form-textarea {
    height: 120px;
    resize: none;
  }

  .toggle-group {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1rem;
  }

  .toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .toggle input {
    display: none;
  }

  .toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 25px;
    transition: 0.4s;
  }

  .toggle input:checked + .toggle-slider {
    background-color: #f93f3f;
  }

  .toggle-slider::before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  .toggle input:checked + .toggle-slider::before {
    transform: translateX(24px);
  }

  .rating-container {
    margin-top: 1rem;
  }

  .violation-check {
    position: unset;
  }
}

.form-footer {
  margin-top: 1rem;
  text-align: right;
}

.select-question-add-method {
  .cards-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }

  .card {
    width: 400px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
  }

  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  .create-questions {
    background-color: #fff4eb;
  }

  .bulk-upload {
    background-color: #eafff1;
  }

  .icon {
    width: 60px;
    height: 60px;
    margin: 0 auto 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .plus {
    font-size: 30px;
    color: #ff8c42;
    font-weight: bold;
  }

  .upload {
    font-size: 30px;
    color: #4caf50;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
  }
}
