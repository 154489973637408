.opportunities-head {
  text-align: center;
  background-color: orange;
  background: linear-gradient(
    to bottom,
    orange 0%,
    orange 50%,
    #ffff 50%,
    #ffffff 100%
  );
  padding-top: 80px;
}
.opportunities-head-title {
  border-radius: 30px;
  height: 50px;
  background-color: #ffff;
  margin-left: 400px;
  margin-right: 400px;
  -moz-box-shadow: 0px 0px 10px 0px #ccc;
  -webkit-box-shadow: 0px 0px 10px 0px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
}

.opportunities-head-title h3 {
  padding-top: 12px;
  align-items: center;
  font-size: 20px;
}

.opportunities-head-btns Button {
  margin: 30px 20px 20px 20px;
}

.nav-tabs {
  display: flex;
  justify-content: space-around;
  text-align: center;
  background-color: #f3f1f2;
  border: none;
  margin-top: 20px;
  padding: 40px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #089ce2;
}

.nav-tabs .nav-link {
  height: 120px;
  width: 150px;
  position: relative;
  background-color: #fff;
  color: black;
  border-radius: 5px;
}

.form {
  padding: 5% 10%;
  width: 100%;
  margin: 0 auto;
}
.form-body {
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-shadow: 6px 5px 10px 6px #ccc;
  -webkit-box-shadow: 6px 5px 10px 6px #ccc;
  box-shadow: 3px 4px 4px 6px #f5f5f5;
}

.input-titlle {
  float: left;
  font-weight: 500;
  width: 100%;
  text-align: left;
  color: #333;
  font-size: 16px !important;
}
.input-titlle-special {
  font-size: 16px !important;
}
.form-control {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  /* border-bottom: 1px solid #dbdbdb !important; */
  border: 1px solid #dbdbdb !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 12px;
}
.form-control.radiocss {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  /* border-bottom: 1px solid #dbdbdb !important; */
  border: none !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 0;
}
.form-check-input {
  accent-color: red;
}
input[type="text"].form-control,
input[type="date"].form-control {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  border: 1px solid #dbdbdb !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 10px;
}
input[type="tel"].form-control {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  border: 1px solid #dbdbdb !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 10px;
}
.form-row.twocolform .form-group-col:last-child {
  margin-left: auto;
}

.form-row.twocolform .col-md-6 {
  flex: 0 0 47%;
  max-width: 47%;
}

.form-row.twocolform .form-group-col {
  width: 47%;
  flex: 0 0 47%;
}

.form-control:focus {
  box-shadow: none !important;
}
.form-group-col {
  margin-bottom: 2.5rem !important;
}

.help-btn {
  background-color: #f5f5fa;
  color: #ff283a;
  font-weight: 800 !important;
  margin: 10px;
  border: none;
}
.save-btn {
  margin: 10px;
  border: none;
}
.formtabs {
  background: #fff;
  height: 135px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 3px 5px #e8e8e8;
  margin-left: 30px;
  cursor: pointer;
}

.tabbackground {
  height: fit-content;
  position: sticky;
  top: 70px;
  margin-left: 2px;
  margin-right: 2px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
}
.mt20 {
  margin-top: 20px;
}
.formtabs p {
  margin-top: 15px;
  font-size: 14px;
}
.formtabs.active {
  background: #854bf6;
}
.formtabs.active p {
  color: #fff !important;
}
.step1head {
  width: 100%;
  /* background: #f3993e; */
  /* color: #fff; */
  /* position: static; */
  /* margin-bottom: -15px; */
}
.form-check {
  position: relative;
  display: inline-flex;
  padding-left: 1.25rem;
  margin-top: 12px;
  /* float: left; */
  margin-right: 8%;
}
.oppfor .form-check {
  margin-right: 5%;
}
.form-body {
  padding: 43px 46px 14px 50px !important;
  border-radius: 10px;
  padding: 25px;
  background: #fff;
  box-shadow: 0px 2px 30px #00000026;
}
.checkboxview {
  padding: 20px;
  background: #f3993e;
  color: #fff;
}
.color-white {
  color: white;
}
.hosting_center {
  width: 100%;
  text-align: center;
}
.hosting_center p {
  margin-bottom: 1px;
}
.hosting_center p:nth-of-type(2) {
  font-weight: 800;
  margin-bottom: 8px;
}
.hostingProgress .progress {
  display: flex;
  height: 3px;
  overflow: hidden;
  line-height: 0;
  background-color: #e9ecef30;
}

.hostingProgress .progress .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff283a;
  transition: width 0.6s ease;
}
/* .form-control
{
    background: #e9f0fa !important;
    height: 48px;
    border: none !important;
    font-size: 14px !important;
} */
/* .box.arrow-bottom:after {
    content: " ";
    position: relative;
    right: -50%;
    bottom: -12px;
    border-top: 15px solid #854bf6;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
  .box.arrow-bottommain:after {
    content: " ";
    position: relative;
    right: -50%;
    bottom: -53px;
    border-top: 30px solid #f3f1f2;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: none;

  } */
.radiocss {
  float: left;
}
.specificradio {
  float: right;
  margin-right: 0 !important;
  margin-top: 0;
}
.rdw-editor-main {
  height: 300px !important;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  padding: 10px;
  margin-top: -5px;
  background-color: #fff;
}
.notifyshow {
  border: 1px solid #ef5f36;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
  background: #ed3836;
  color: #fff !important;
  text-align: left;
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;
}
.mainrow {
  display: inherit;
  /* width: 72%; */
  text-align: left;
  margin-top: 15px;
}
.remove-field {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}
.addminus {
  display: grid;
}
.add-date-btn {
  width: 100%;
}
.minus-btn {
  padding: 0;
  font-size: 30px;
  color: red;
}
.plus-btn {
  padding: 0;
  font-size: 30px;
  color: green;
}
.inputboxcss {
  border-radius: 6px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  background: #fff;
  margin-bottom: 20px;
  padding-top: 20px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.checkbox-control {
  float: left;
  margin-right: 14px;
  z-index: 0 !important;
}
.lablecls {
  margin-top: 20%;
}
.headerlink {
  margin-right: 35px;
}

/* .minus-btn{ margin-right: 15px;} */

.add-round {
  color: #ff283a;
  min-width: 100px;
  border-radius: 4px;
  padding: 15px 18px;
  font-size: 15px;
  font-weight: 800 !important;
  /* width: 40%; */
  /* border: 4px solid #fff; */
  /* box-shadow: 0 0 0 1px #176ede; */
  font-weight: 400;
  margin-top: 22px;
  margin-left: auto;
  text-align: center !important;
  background: rgba(255, 40, 58, 0.1);
}
.add-round .fa.fa-plus {
  margin-right: 5px;
  /* font-weight: 400; */
}

/* .add-round:hover
{
    color: #fff;
    min-width: 100px;
    border-radius: 4px;
    padding: 9px 18px;
    font-size: 15px;
    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #176ede;
    margin-left: auto;
    background: #5736ba;
    text-decoration: none;
} */
.remove-round {
  color: #fff;
  min-width: 100px;
  border-radius: 4px;
  padding: 9px 18px;
  /* width: 120px; */
  font-size: 15px;
  /* width: 40%; */
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px #176ede;
  margin-left: auto;
  background: red;
}

.round-settings {
  top: 70px;
}

.remove-round:hover {
  color: #fff;
  min-width: 100px;
  border-radius: 4px;
  padding: 9px 18px;
  font-size: 15px;
  /* width: 40%; */
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px #176ede;
  margin-left: auto;
  background: red;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}
.fr {
  text-align: right;
}
.fr .btnshow {
  margin-right: 9px;
}

@media screen and (max-width: 480px) {
  .form-row.twocolform .col-md-6 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .form .form-body {
    padding: 40px 25px !important;
  }

  .formtabs p {
    margin-top: 9px;
    line-height: 17px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
  }
  .formtabs img {
    width: 35px;
    margin-top: 0px;
  }
  .tc {
    display: flex;
    margin-top: 0;
    text-align: left;
  }
  .help-btn {
    height: max-content;
    margin: 0;
  }
  .save-btn {
    margin: 0px 0 0px 10px;
  }
  .formtabs {
    height: 120px;
    width: 113px;
    border-radius: 5px;
    box-shadow: 3px 5px #e8e8e8;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-left: 10px;
    margin-bottom: 23px;
  }

  .box.arrow-bottom:after {
    left: 43%;
    bottom: -10px;
  }
  .mobile-ml-0 {
    margin-left: 0;
  }
  /*  .tab-names .row > div{display: block;     display: contents;
                width: 100%;}
 */

  .formtabs.active {
    margin-bottom: 0;
  }
}

.hosting_bottom {
  padding: 10px 10% 10px 10%;
  background: #f5f5fa !important;
  width: 100%;
  display: flow-root;
  position: fixed;
  bottom: 0;
}
.save-hosting-btn {
  margin: 10px;
  border: none;
  float: right;
  background: #ff283a;
  color: #fff;
  height: 38px;
  font-weight: 800 !important;
}
.save-hosting-first-btn {
  margin: 10px;
  border: none;
  float: right;
  background: #ff283a;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
}
.help-btn svg {
  margin-right: 10px;
}
@media screen and (max-width: 1023px) {
  .form-control {
    height: auto !important;
    padding-bottom: 15px;
  }
  .tc {
    margin-top: 0;
    text-align: left;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .form-control {
    height: auto !important;
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .form {
    width: 100%;
    background: #eff4f9;
  }
  .form.teamaddform {
    padding: 20px 10% 3% 10%;
  }
}
.sameasleader {
  right: 0;
  position: absolute;
  color: #ff283a;
  top: 0;
}
.round-tittle {
  position: relative;
  color: #ff6002;
  margin-bottom: 30px;
}
.round-tittle:before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  content: attr(data-content);
  background-color: #fff;
  color: #fff;
  z-index: -1;
  padding-right: 0.5em;
}
.round-tittle:after {
  position: absolute;
  content: "";
  display: block;
  left: 9%;
  right: 0;
  top: 50%;
  background-color: #a5a5a5;
  height: 0.1em;
  min-height: 1px;
  /* z-index:999; */
}
.uploader #round,
#start {
  float: left;
  clear: both;
  padding: 1%;
  border: 2px dashed #ff283a;
  width: 100%;
}

#round .fa-download {
  color: #ff283a;
}
#round,
#start div {
  color: #ff283a;
}
.prizetittle {
  position: relative;
  color: #ff6002;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 22px;
  font-size: 16px;
  text-transform: uppercase;
}
.prizetittle:before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  content: attr(data-content);
  background-color: #fff;
  color: #fff;
  z-index: -1;
  padding-right: 0.5em;
}
.prizetittle:after {
  position: absolute;
  content: "";
  display: block;
  left: 9%;
  right: 0;
  top: 50%;
  background-color: #a5a5a5;
  height: 1px;
  min-height: 1px;
}
.prizetittle.impdate:after {
  left: 14%;
}
.prizetittle.impcontact:after {
  left: 21%;
}
.priceadd {
  width: 100%;
}
.prizetittle.leader:after {
  left: 14%;
}
.prizetittle.memberdetail:after {
  left: 14%;
}
#start .fa-upload:before {
  color: #ff283a;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #191f23;
  font-size: 16px;
  font-weight: 600;
}
.uploader.logosec label {
  float: left;
  clear: both;
  width: 100%;
  padding: 0px !important;
  text-align: center;
  /* background: #fff; */
  border: none;
  border-radius: 7px;
  /* border: 3px solid #eee; */
  /* transition: all 0.2s ease; */
  /* user-select: none; */
}
.register_add {
  background-color: rgba(255, 40, 58, 0.1);
  padding: 12px 5px 5px 10px;
}
.register_add button h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #ff283a;
}
.teamaddform .form-body {
  padding: 30px 38px 0px 28px !important;
}
.form-group-col.teamtitle {
  margin-bottom: 0px !important;
  margin-top: 3%;
}
.teamaddform h4 svg {
  color: #ff283a;
  margin-right: 10px;
}
.registrationmainrow .form-row div label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.registrationmainrow .form-row div div .form-check label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

/* calendar change */

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  /* This is the calendar icon in FontAwesome */
  width: 15px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 6px;
  color: #383838;
}
.displaynone {
  display: none;
}
.form-control.disabled.required {
  background: #cccccc38 !important;
  cursor: not-allowed;
}
.select-category {
  width: 100%;
  height: 48px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 6px;
  font-size: 16px;
}
.select-category.fill-the-field {
  border: 1px solid #f90202;
}
.opportunity-lists {
  width: 100%;
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.opportunity-item {
  width: fit-content;
  padding: 10px;
  background-color: #ff283a;
  color: white !important;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.festival-suggestion-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 99%;
  border: 1.5px solid #ccc;
  background-color: white;
  list-style: none;
  padding: 0;
  margin-bottom: 5px;
  margin-left: 5px;
  max-height: 116px;
  overflow-y: auto;
  z-index: 1;
}

.festival-suggestion-item {
  padding: 8px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.festival-suggestion-item:hover {
  background-color: rgb(244, 194, 194);
}

.create-new-festival-btn-conainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-new-festival-btn-conainer button {
  margin-top: 10px;
}

.round-dates-group {
  border: 1px solid;
  border-radius: 15px;
  padding: 20px;

  input[type="checkbox"] {
    left: unset;
    position: unset;
    height: 18px;
    width: 18px;
    margin: 0;
    display: inline-block;
  }

  input[type="date"]:disabled {
    background-color: #e3e2e2 !important;
  }
}
