.quiz-leaderboard-page {
  width: 100%;

  .quiz-leaderboard-main-content {
    min-height: 90dvh;
    margin-top: 70px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* General responsive container */
    .leaderboard-container {
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 16px;
      box-sizing: border-box;
      font-family: Arial, sans-serif;
    }

    /* Team card - responsive */
    .team-card {
      padding: 16px;
      margin-bottom: 16px;
      border-radius: 12px;
      border-left: 4px solid #f8b63c;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
    }

    .team-card:hover {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      transform: scale(1.01);
      transition: transform 0.3s ease;
    }

    /* Team header section - flexible layout */
    .team-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;
      flex-wrap: wrap;
    }

    .team-rank-name {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      flex: 1;
      min-width: 200px;
    }

    .team-rank-container {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .rank-decoration {
      position: absolute;
      font-size: 16px;
    }

    .rank-decoration.left {
      left: -18px;
    }

    .rank-decoration.right {
      right: -18px;
    }

    /* For top 3 ranks, make the numbers slightly larger */
    .team-rank-container .team-rank {
      font-size: 24px;
      margin: 0;
    }

    .team-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 0; /* Allow text truncation */
    }

    .team-name {
      margin: 0;
      font-size: 18px;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .team-members {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #777;
      margin-top: 4px;
      width: 100%;
    }

    .member-icon {
      margin-right: 8px;
      font-size: 14px;
      flex-shrink: 0;
      color: #3a3a3a;
    }

    .member-names {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .score-circle {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      margin-left: auto;
    }

    /* Team metrics section - responsive grid */
    .team-metrics {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px;
      padding-left: 2.6rem;
      padding-right: 2.6rem;
    }

    .metric {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: calc(33% - 8px);
    }

    .metric-icon {
      width: 24px;
      height: 24px;
      background-color: #f0f5ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 14px;
      flex-shrink: 0;
    }

    .time-icon {
      background-color: #e6f7ff;
      color: #2196f3;
    }

    .questions-icon {
      background-color: #e6ffe6;
      color: #4caf50;
    }

    .completion-icon {
      background-color: #f0e6ff;
      color: #9c27b0;
    }

    .metric-content {
      display: flex;
      flex-direction: column;
      min-width: 0; /* Allow text truncation */
    }

    .metric-label {
      font-size: 12px;
      color: #888;
    }

    .metric-value {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .completion-badge {
      background-color: #ffeeee;
      color: #e04a4a;
      font-size: 12px;
      font-weight: bold;
      padding: 2px 8px;
      border: 1px solid #e04a4a;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
    }

    /* Pagination - responsive */
    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      flex-wrap: wrap;
      gap: 5px;
    }

    .pagination button {
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 50%;
      background-color: #f0f0f0;
      cursor: pointer;
    }

    .pagination button.active {
      background-color: #e04a4a;
      color: white;
    }

    .leaderboard-header-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
      border: 1px solid #e0e0e0;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      margin-bottom: 2.5rem;
      background-color: #ffffff;
      transition: all 0.3s ease;
    }

    .leaderboard-header-card:hover {
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
    }

    .leaderboard-header-left-content h3 {
      margin: 0 0 0.5rem 0;
      font-size: 1.8rem;
      color: #333;
      font-weight: 600;
    }

    .leaderboard-header-left-content h6 {
      margin: 0 0 1rem 0;
      font-size: 1rem;
      color: #666;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    .leaderboard-info {
      display: flex;
      width: max-content;
      padding: 0.6rem 1rem;
      align-items: center;
      gap: 0.6rem;
      font-size: 0.95rem;
      margin-top: 0.8rem;
      border: 1px solid #eaeaea;
      border-radius: 12px;
      color: #555;
      background-color: #f9f9f9;
      transition: all 0.2s ease;
    }

    .leaderboard-info:hover {
      background-color: #f0f0f0;
    }

    .leaderboard-info svg {
      color: #cc0000;
    }

    .leaderboard-info b {
      font-weight: 600;
      color: #444;
    }

    .leaderboard-header-right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.4rem;
      padding-left: 1rem;
    }

    .leaderboard-header-right-content h4 {
      margin: 0;
      font-size: 1rem;
      color: #666;
      font-weight: 500;
    }

    .leaderboard-header-right-content h3 {
      margin: 0;
      font-weight: 600;
    }

    .round-end-date {
      display: flex;
      width: max-content;
      padding: 0.8rem 1.2rem;
      align-items: center;
      gap: 0.5rem;
      border: 1px solid #cc0000;
      border-radius: 12px;
      margin: 0.4rem 0 0 0;
      background-color: #fff2f2;
      color: #cc0000;
      font-size: 18px;
      font-weight: 600;
      box-shadow: 0 2px 6px rgba(0, 102, 204, 0.1);
    }

    .round-end-date:hover {
      scale: 1.02;
      transition: scale 0.2s ease;
    }

    /* Responsive styles */
    @media (max-width: 768px) {
      .leaderboard-header-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 1.2rem;
      }

      .leaderboard-header-right-content {
        margin-top: 1.5rem;
        align-items: flex-start;
        padding-left: 0;
        width: 100%;
      }

      .round-end-date {
        width: 100%;
        justify-content: center;
      }
    }

    @media (max-width: 480px) {
      .leaderboard-header-left-content h3 {
        font-size: 1.5rem;
      }

      .leaderboard-info {
        width: 100%;
        justify-content: center;
      }
    }

    /* Mobile responsiveness */
    @media (max-width: 480px) {
      .team-header {
        flex-direction: column;
      }

      .score-circle {
        margin: 8px 0 0 0;
      }

      .team-metrics {
        flex-direction: column;
      }

      .metric {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    /* Small screens */
    @media (max-width: 600px) {
      .team-card {
        padding: 12px;
      }

      .team-rank {
        font-size: 20px;
      }

      .team-name {
        font-size: 16px;
      }
    }

    /* Medium screens */
    @media (min-width: 601px) and (max-width: 900px) {
      .metric {
        min-width: calc(50% - 8px);
      }
    }
  }

  .footer {
    display: block;
  }
}
